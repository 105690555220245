import { createSelector } from '@reduxjs/toolkit';
import performerCategory from '@dh-msc/performer-category';
import {
    ExclusiveBadgeStatusEnum,
    MeEntity,
    PerformerEntity,
    PerformerEntityStatus,
} from 'domains/Performer/Performer.types';
import { RootState } from '../../store';

const _getMe = (state: RootState): MeEntity | undefined => state.performer.me;
const _getMyPerformer = (state: RootState): PerformerEntity | undefined => state.performer.myPerformer;

export const selectIsGoodieBasketEnabled = (state: RootState) => !state.performer.me?.person.isGiftSendingDisabled;
export const selectIsStudioCertified = (state: RootState) => state.performer.me?.studio?.certificationFlag === 1;

export const selectStudioId = (state: RootState) => state.performer.me?.studio?.id;
export const selectStudioOwnerUserId = (state: RootState) => state.performer.me?.studio?.ownerUserId;

export const selectIsStudioOwner = (state: RootState) =>
    !!(state.performer.me && state.performer.me.id === state.performer.me.studio.ownerUserId);

export const selectIsActorOfPerformer = createSelector(
    [_getMe, _getMyPerformer],
    (me, myPerformer) => !!(me && myPerformer && myPerformer.persons.some((person) => person.id === me.id)),
);
export const selectIsImpersonating = createSelector(
    [_getMe, _getMyPerformer],
    (me, myPerformer) =>
        myPerformer && !myPerformer.persons.map((person) => person.id).includes(me?.studio.ownerUserId as number),
);

export const selectMe = (state: RootState) => state.performer.me;
export const selectPerformerViewType = (state: RootState) => state.performer.me?.person.viewType;
export const selectPerformerFirstName = (state: RootState) => state.performer.me?.person.firstName;
export const selectPerformerGender = (state: RootState) => state.performer.me?.person.gender;
export const selectPerformerIsMale = (state: RootState) => state.performer.me?.person.gender === 'male';
export const selectMyPerformer = (state: RootState) => state.performer.myPerformer;
export const selectMyPerformerId = (state: RootState) => state.performer.myPerformer?.id;
export const selectMyPerformerCategoryId = (state: RootState) => state.performer.myPerformer?.category.id;

export const selectMyPerformerPersons = (state: RootState) => state.performer.myPerformer?.persons;
export const selectMyPerformerStatus = (state: RootState) => state.performer.myPerformer?.status;

export const selectMyPerformerDisplayName = (state: RootState) => state.performer.myPerformer?.displayName;
export const selectMyPerformerHasInvalidDisplayName = (state: RootState) =>
    state.performer.myPerformer?.hasInvalidDisplayName;
export const selectMyPerformerScreenName = (state: RootState) => state.performer.myPerformer?.screenName;
export const selectIsPerformerActive = (state: RootState) =>
    state.performer.myPerformer?.status === PerformerEntityStatus.ACTIVE;
export const selectIsPerformerNew = (state: RootState) =>
    state.performer.myPerformer?.status === PerformerEntityStatus.NEW;
export const selectIsPerformerInvisible = (state: RootState) => state.performer.myPerformer?.isInvisible;
export const selectIsPerformerLiveShowOnline = (state: RootState) => state.performer.myPerformer?.liveShow.isOnline;

export const selectIsAmateur = (state: RootState) =>
    performerCategory.is.amateur(state.performer.myPerformer?.category?.id);
export const selectIsFreemium = (state: RootState) =>
    performerCategory.is.freeShow(state.performer.myPerformer?.category?.id);
export const selectIsFetish = (state: RootState) =>
    performerCategory.is.fetish(state.performer.myPerformer?.category?.id);
export const selectIsNonNude = (state: RootState) =>
    performerCategory.is.nonNude(state.performer.myPerformer?.category?.id);
export const selectIsSelectedModel = (state: RootState) => !!state.performer.myPerformer?.isSelected;
export const selectIsNudeCategory = (state: RootState) =>
    performerCategory.is.nude(state.performer.myPerformer?.category?.id);
export const selectIsExclusiveBadgeStatusActive = (state: RootState) =>
    state.performer.myPerformer?.exclusiveBadgeStatus?.id === ExclusiveBadgeStatusEnum.Active;

export const selectUserId = (state: RootState) => state.performer.me?.id;
export const selectRegistrationDate = (state: RootState) => state.performer.myPerformer?.createdAt;
export const selectMyPerformerPersonIds = createSelector([_getMyPerformer], (myPerformer) =>
    myPerformer?.persons.map((person) => person.id),
);

export const selectIsPerformerClosed = (state: RootState) =>
    state.performer.myPerformer?.status === PerformerEntityStatus.CLOSED;
export const selectIsPerformerSuspended = (state: RootState) =>
    state.performer.myPerformer?.status === PerformerEntityStatus.SUSPENDED;

export const selectPerformerPricing = (state: RootState) => state.performerPricing.pricing;
export const selectPerformerPricingStatus = (state: RootState) => state.performerPricing.pricingStatus;

export const selectSelectedLanguage = (state: RootState) => state.performer.language;
