/* eslint-disable complexity */

export default async function pageLoader(pageId) {
    let controller;

    switch (pageId) {
        case 'IndexCelebrity':
            controller = await import('./Celebrity/js/Celebrity');
            break;
        case 'ModelsIndex':
            controller = await import('./Models/Models');
            break;
        case 'IndexFaq':
            controller = await import('./Faq/Faq');
            break;
        case 'SignupSignupflow':
        case 'AuthenticationConfirmemail':
            controller = await import('./Authentication/SignupFlow');
            break;
        case 'IntroductionsettingsIndex':
            controller = await import('./IntroductionSettings/IntroductionSettings');
            break;
        case 'PersonaldatasettingsIndex':
            controller = await import('./PersonalDataSettings/PersonalDataSettings');
            break;
        case 'SelectionIndex':
            controller = await import('./Selection/Selection');
            break;
        case 'SnapshotIndex':
            controller = await import('./Snapshot/Snapshot');
            break;
        case 'MemberreferralIndex':
            controller = await import('./MemberReferral/MemberReferral');
            break;
        case 'MycontentfreeeditorIndex':
            controller = await import('./MyContent/FreeEditorPage');
            break;
        case 'MycontentpremiumeditorIndex':
            controller = await import('./MyContent/PremiumEditorPage');
            break;
        case 'StatisticsPerformerlasttransactions':
            controller = await import('./Statistics/PerformerLastTransactions');
            break;
        case 'StatisticsOverview':
        case 'StatisticsPerformeroverview':
            controller = await import('./Statistics/Overview');
            break;
        case 'StatisticsTransactions':
            controller = await import('./Statistics/Transactions');
            break;
        case 'StatisticsLasttransactions':
        case 'StatisticsIncomelevels':
            controller = await import('./Statistics/LastTransactions');
            break;
        case 'IncomestatisticsIndex':
            controller = await import('./IncomeStatistics/IncomeStatistics');
            break;
        case 'PayoutoverviewIndex':
            controller = await import('./Payout/PayoutOverview');
            break;
        case 'PayoutoptionsIndex':
        case 'PayoutoptionsgeneralIndex':
        case 'CompanyData':
            controller = await import('./Payout/PayoutOptions');
            break;
        case 'PayoutoptionsChange':
            controller = await import('./Payout/PayoutOptionChange');
            break;
        case 'CompanyManagingdirectorregistration':
            controller = await import('./Company/ManagingDirectorRegistration');
            break;
        case 'CompanyAddinformation':
            controller = await import('./Company/AddInformation');
            break;
        case 'IdentificationprocessCompanydatachange':
            controller = await import('./Identificationprocess/CompanyDataChange');
            break;
        case 'SupportmessengerListmessages':
            controller = await import('./Message/Message');
            break;
        case 'SupportmessengerReadmessage':
            controller = await import('./Message/ReadSupportMessage');
            break;
        case 'ErrorError':
            controller = await import('./Error/Error');
            break;
        case 'MigrationChooseperson':
            controller = await import('./Migration/ChoosePerson');
            break;
        case 'BillingaddressIndex':
            controller = await import('./Billingaddress/Billingaddress');
            break;
        case 'PromotiontoolsDesktop':
        case 'PromotiontoolsMobile':
        case 'PromotiontoolsVideocall':
            await import('./PromotionTools/app');
            break;

        // Pages without real controllers
        case 'PayoutRecipienttypeselector':
            await import('./Payout/PayoutRecipientTypeSelector');
            break;
        case 'ProtectionIndex':
            await import('./Protection/Protection');
            break;
        case 'PromovideoIndex':
            await import('./PromoVideo/PromoVideo');
            break;
        case 'CompanyFinishregistration':
            await import('./Payout/CompanyFinishRegistration');
            break;
        case 'TwowayaudiointroductionIndex':
            await import('./TwoWayAudioSettings/TwoWayAudioSettings');
            break;
        case 'Cam2camIndex':
            await import('./Cam2Cam/Cam2Cam');
            break;
        case 'VideovoicecallintroductionIndex':
            await import('./VideoVoiceCallIntroduction/VideoVoiceCallIntroduction');
            break;
        case 'MystoryintroductionIndex':
            await import('./MyStoryIntroduction/MyStoryIntroduction');
            break;
        case 'PrivategameIndex':
            await import('./PrivateGame/PrivateGame');
            break;
        case 'ClubeliteIndex':
            await import('./ClubElite/ClubElite');
            break;
        case 'ExclusivemodelIndex':
            await import('./ExclusiveModel/ExclusiveModel');
            break;
        case 'KingoftheroomIndex':
            await import('./KingOfTheRoom/KingOfTheRoom');
            break;
        case 'PrivateshowdiscountIndex':
            await import('./PrivateShowDiscount/PrivateShowDiscount');
            break;
        case 'SupplementalservicesagreementIndex':
            await import('./SupplementalServicesAgreement/SupplementalServicesAgreement');
            break;
        case 'MessagingstreakIndex':
            await import('./MessagingStreak/MessagingStreak');
            break;
        case 'PolicyPrivacypolicy':
        case 'PolicyCookiepolicy':
        case 'PolicyServicesagreement':
        case 'PolicyEmailmarketingprivacypolicy':
            await import('../Overlay/ServicesAgreement/ServicesAgreement');
            break;
    }
    return controller ? controller.default : false;
}
