export interface JawsToken {
    accessToken: string;
}

export interface PerformerJawsToken extends JawsToken {
    performerId: number;
}

export const JawsEvents = {
    MESSAGE: {
        MEMBER_TO_PERFORMER_LIVE: 'notification.performer.member.message.delivery.live',
        PERFORMER_MESSAGE_SENT: 'notification.performer.message.sent.delivery.live',
        MESSENGER_MESSAGE_UPDATED: 'notification.messenger.message-updated.delivery.live',
        PERFORMER_MASS_MESSAGE_SENT: 'notification.performer.mass_message.sent.delivery.live',
        PERFORMER_THREAD_SEEN_BY_MEMBER: 'notification.performer.messenger.v3.thread.seen.by_member.delivery.live',
    },
    NOTIFICATION: {
        COMPETITOR_REWARDED: 'notification.competition.competitor.rewarded.delivery.live',
        FAVOURITE_ADDED: 'notification.performer.member.favourite.added.delivery.live',
        CHANNEL_CONTENT_COMMENTED: 'notification.channel.content.commented.delivery.live',
        CHANNEL_CONTENT_LIKED: 'notification.channel.content.liked.delivery.live',
        IMAGEFOLDER_SUBSCRIBED: 'notification.mycontent.imagefolder.subscription.created.delivery.live',
        VIDEO_SUBSCRIBED: 'notification.mycontent.video.subscription.created.delivery.live',
        PERFORMER_STATUS_UPDATED: 'notification.performer.status.updated.delivery.live',
        VIDEO_CALL_ENABLED: 'notification.performer.video_call.enabled.delivery.live',
        VIDEO_CALL_DISABLED: 'notification.performer.video_call.disabled.delivery.live',
        MOBILE_LIVE_ENABLED: 'notification.performer.mobile_live.enabled.delivery.live',
        MOBILE_LIVE_DISABLED: 'notification.performer.mobile_live.disabled.delivery.live',
        PROFILE_PICTURE_ACCEPTED: 'notification.performer.profile_picture.accepted.delivery.live',
        PROFILE_PICTURE_REJECTED: 'notification.performer.profile_picture.rejected.delivery.live',
        STORY_SUBSCRIPTION: 'notification.my_story.story.subscription.created.delivery.live',
        REFERRAL_CONSENT_ACCEPTED: 'notification.performer.performer_referral.share_income.overrode.delivery.live',
        REFERRAL_CONSENT_REVOKED: 'notification.performer.performer_referral.consent.revoked.delivery.live',
        REFERRAL_CONNECTION_CREATED: 'notification.performer.performer_referral.connection.created.delivery.live',
        REFERRAL_CONNECTION_CUT: 'notification.performer.performer_referral.connection.cut.delivery.live',
        HOT_SHOW_ENDED: 'notification.performer.hot_show.ended.delivery.live',
        BATTLE_ENDED: 'notification.performer.battle.ended.delivery.live',
        BATTLE_ENABLED: 'notification.performer.battle.enabled.delivery.live',
        BATTLE_DISABLED: 'notification.performer.battle.disabled.delivery.live',
        ACTION_REQUESTS_DISABLED: 'notification.performer.action_requests.disabled.delivery.live',
        INSTANT_PAYOUTS_UPDATED: 'notification.payout_settings.instant_payouts.updated.delivery.live',
        INTERACTIVE_TOY_DISABLED: 'notification.performer.interactive_toy.disabled.delivery.live',
        INTERNAL_PARTNER_CONSENT_REVOKED: 'notification.internal_partner.consent.revoked',
        MY_STORY_ITEM_REJECTED: 'notification.performer.story.item.rejected.delivery.live',
        CHANNEL_CONTENT_ITEM_REJECTED: 'notification.performer.channel.content.rejected.delivery.live',
        PROMOTION_TEASER_PARTICIPANT_ADDED: 'notification.performer.promotion.teaser.participant.added',
        PROMOTION_TEASER_PARTICIPANT_CONFIRMATION_REQUIRED:
            'notification.promotion.teaser.participant.confirmation_required',
        PROMOTION_TEASER_STATUS_ACCEPTED: 'notification.promotion.teaser.status.accepted.delivery.live',
        PROMOTION_TEASER_STATUS_REJECTED: 'notification.promotion.teaser.status.rejected.delivery.live',
        PROMOTOOLS_PROMOTION_CONTENT_STATUS_ACCEPTED:
            'notification.promotools.promotion_content.status.accepted.delivery.live',
        PROMOTOOLS_PROMOTION_CONTENT_STATUS_REJECTED:
            'notification.promotools.promotion_content.status.rejected.delivery.live',
        FANCLUB_MEMBER_SUBSCRIBED: 'notification.fanclub.member_subscribed.delivery.live',
        FANCLUB_MEMBER_UNSUBSCRIBED: 'notification.fanclub.member_unsubscribed.delivery.live',
        FANCLUB_MEMBER_RESUBSCRIBED: 'notification.fanclub.member_resubscribed.delivery.live',
        PERFORMER_SURVEY: 'notification.performer.survey_notification.delivery.live',
        EXCLUDED_AWARD_GAME: 'notification.competition.performer.excluded.delivery.live',
        SHOP_PAYMENT_ORDER_PLACED: 'notification.performer.shop.payment_order.placed.delivery.live',
        DIRECT_PAYOUT_PENDING: 'notification.direct_payout.pending.delivery.live',
        DIRECT_PAYOUT_APPROVED: 'notification.direct_payout.approved.delivery.live',
        DIRECT_PAYOUT_DELETED: 'notification.direct_payout.deleted.delivery.live',
        DIRECT_PAYOUT_DISABLED_BY_SUPPORT: 'notification.direct_payout.disabled_by_support.delivery.live',
        HIDDEN_FACE_SETTING_CHANGED: 'notification.performer.hidden_face.setting.changed.delivery.live',
    },
    MY_STORY: {
        MY_STORY_ITEM_ACTIVATED: 'notification.performer.story.item.activated.delivery.live',
        MY_STORY_ITEM_REJECTED: 'notification.performer.story.item.rejected.delivery.live',
        MY_STORY_ITEM_STORED: 'notification.performer.story.item.stored.delivery.live',
        MY_STORY_ITEM_UPLOADED: 'notification.performer.story.item.uploaded.delivery.live',
        MY_STORY_ITEM_VALIDATION_FAILED: 'notification.performer.story.item.validation_failed.delivery.live',
    },
    PROFILE_PICTURE: {
        PROFILE_PICTURE_CONVERTED: 'notification.performer.profile_picture.converted.delivery.live',
        PROFILE_PICTURE_VALIDATION_FAILED: 'notification.performer.profile_picture.validation_failed.delivery.live',
    },
    INTERACTION: {
        INTERACTION_CREATED: 'notification.performer.member.interaction.created.delivery.live',
    },
};

export interface JawsNotification {
    header: JawsHeader;
    body: JawsBody;
}

export interface JawsHeader {
    'eds-client': string;
    'eds-endpoint': string;
    'eds-notification-id': string;
    event: string;
}

export type JawsBody = JawsStoryNotification;

export interface JawsStoryNotification extends JawsBodyAbstract {
    story: {
        id: number;
        ownerId: number;
    };
    storyItem: {
        contentType: string;
        createdAt: string;
        id: number;
        privacy: string;
        reason: {
            description: string;
            title: string;
        };
        status: string;
        type: string;
        updatedAt: string;
    };
}
export interface JawsBodyAbstract {
    createdAt: string;
    timestamp: number;
}
